import request from '@/util/request'
export default{
	//登录
	login(data){
		return request({url: '/manager/login/doLogin',method: 'post',data})		
	},	
	//登出
	logout(data){
		return request({url: '/manager/login/logout',method: 'post',data})		
	},	
	//修改密码
	setMannerPassword(data){
		return request({url: 'manager/user/setPassword',method: 'post',data})		
	},	
	//谷歌验证检查
	validateGcode(data){
		return request({url: '/manager/login/validateGcode',method: 'post',data})		
	},
	//谷歌验证码绑定
	bingGoogle(data){
		return request({url: '/manager/login/bindGoogle',method: 'post',data})		
	},
	//新增代理
	addAgent(data){
		return request({url: '/manager/member/addAgent',method: 'post',data})		
	},
	//代理列表
	agentList(data){
		return request({url: '/manager/member/agent',method: 'post',data,allowRepetition:true})		
	},
	//修改代理
	editAgent(data){
		return request({url: '/manager/member/editAgent',method: 'post',data})		
	},
	//重置代理密码
	setAgentPassword(data){
		return request({url: '/manager/member/setAgentPassword',method: 'post',data})		
	},
	
	//新增商家
	addTrade(data){
		return request({url: '/manager/member/addTrader',method: 'post',data})		
	},
	//商家列表
	traderList(data){
		return request({url: '/manager/member/trader',method: 'post',data,allowRepetition:true})		
	},
	//修改商家
	editTrader(data){
		return request({url: '/manager/member/editTrader',method: 'post',data})		
	},
	//重置商家密码
	setTraderPassword(data){
		return request({url: '/manager/member/setTraderPassword',method: 'post',data})		
	},
	//商家账号启用/停用
	traderToggle(data){
		return request({url: '/manager/member/traderToggle',method: 'post',data})		
	},
	//商家 设置代付密码
	setTraderPayPassword(data){
		return request({url: '/manager/member/setTraderPayPassword',method: 'post',data})		
	},
	//商家 冻结/解冻余额
	setAmount(data){
		return request({url: '/manager/member/setAmount',method: 'post',data})		
	},
	//商家 手续费列表
	traderFeeList(data){
		return request({url: '/manager/member/traderFeeList',method: 'post',data})		
	},
	//商家 手续费列表
	traderLimitList(data){
		return request({url: '/manager/member/traderGoldList',method: 'post',data})		
	},
	//商家 设置手续费
	setTraderFee(data){
		return request({url: '/manager/member/setTraderFee',method: 'post',data})		
	},
	//商家 设置单笔限额
	setTraderGold(data){
		return request({url: '/manager/member/setTraderGold',method: 'post',data})		
	},
	//代理 手续费列表
	agentFeeList(data){
		return request({url: '/manager/member/agentFeeList',method: 'post',data})		
	},
	//代理 手续费列表
	agentTraderFeeList(data){
		return request({url: '/manager/member/agentTraderFeeList',method: 'post',data})		
	},	
	//代理 设置手续费
	setAgentFee(data){
		return request({url: '/manager/member/setAgentFee',method: 'post',data})		
	},
	//商家 支付权限配置列表
	traderPayList(data){
		return request({url: '/manager/member/traderPayList',method: 'post',data})		
	},
	//商家 支付权限配置列表
	setTraderPayWay(data){
		return request({url: '/manager/member/setTraderPayWay',method: 'post',data})		
	},
	//商家 支付权限通道百分比
	setRratioList(data){
		return request({url: '/manager/member/ratioList',method: 'post',data})		
	},
	//商家 支付权限通道百分比
	setRatio(data){
		return request({url: '/manager/member/setRatio',method: 'post',data})		
	},
	//通道管理  通道系统管理
	paywayAllList(data){
		return request({url: '/manager/payway_type/allList',method: 'post',data})		
	},
	//通道管理  通道系统管理
	//show_repay 是否返回自动代付 0-不显示 1-显示
	paywayfeeList(data){
		return request({url: '/manager/payway_type/feeList',method: 'post',data})		
	},
	//通道管理  通道系统管理
	paywaysetFee(data){
		return request({url: '/manager/payway_type/setFee',method: 'post',data})		
	},
	//通道管理  通道系统管理
	paywaysetGold(data){
		return request({url: '/manager/payway_type/setGold',method: 'post',data})		
	},	
	//通道列表
	passagewayList(data){
		return request({url: '/manager/payway_type/traderList',method: 'post',data})		
	},
	//码商 小组数列表
	carderAll(data){
		return request({url: '/manager/carder/all',method: 'post',data})		
	},
	//码商 小组详情
	carderInfo(data){
		return request({url: '/manager/carder/info',method: 'post',data})		
	},
	//码商 上级代理
	showAgent(data){
		return request({url: '/manager/carder/showAgent',method: 'post',data})		
	},
	//码商 充值 / 扣款
	carderSetAmount(data){
		return request({url: '/manager/carder/setAmount',method: 'post',data})		
	},
	//码商 开工/停工
	setState(data){
		return request({url: '/manager/carder/setState',method: 'post',data})		
	},
	//码商 重置密码
	setPassword(data){
		return request({url: '/manager/carder/setPassword',method: 'post',data})		
	},
	//码商 重置密码
	emptyMoney(data){
		return request({url: '/manager/carder/emptyMoney',method: 'post',data})		
	},
	//码商 重置密码
	setAdvanceMoney(data){
		return request({url: '/manager/carder/setAdvanceMoney',method: 'post',data})		
	},
	//码商 重置密码
	setGoogleKey(data){
		return request({url: '/manager/carder/setGoogleKey',method: 'post',data})		
	},
	//商家 重置密码
	setMemberGoogleKey(data){
		return request({url: '/manager/member/setGoogleKey',method: 'post',data})		
	},
	//通道管理 支付 代理银行卡监控 代理树
	treeList(data){
		return request({url: '/manager/carder/treeList',method: 'post',data})		
	},
	//通道管理 支付 代理银行卡监控 银行卡列表 carder_id 码商ID  business_no:商家号查询
	cardList(data){
		return request({url: '/manager/card/cardList',method: 'post',data})		
	},
	//通道管理 支付 代理银行卡监控 获取标签
	cardTags(data){
		return request({url: '/manager/card/tags',method: 'post',data})		
	},
	//通道管理 支付 代理银行卡监控 设置标签
	setTag(data){
		return request({url: '/manager/card/setTag',method: 'post',data})		
	},
	//通道管理 支付 代理银行卡监控 离线 1在线  0离线
	setOnline(data){
		return request({url: '/manager/card/setOnline',method: 'post',data})		
	},
	//通道管理 支付 代理银行卡监控 状态修改   0启用  1停用
	cardToggle(data){
		return request({url: '/manager/card/cardToggle',method: 'post',data})		
	},
	//通道管理 支付 代理银行卡监控 删除银行卡
	carddelete(data){
		return request({url: '/manager/card/delete',method: 'post',data})		
	},
	//通道管理 支付 代理银行卡监控 连续失败次数 清零统计
	cardcleared(data){
		return request({url: '/manager/card/cleared',method: 'post',data})		
	},
	//银行卡流水  进账
	moneyList(data){
		return request({url: '/manager/card/inList',method: 'post',data})		
	},
	//银行卡出账
	outList(data){
		return request({url: '/manager/card/outList',method: 'post',data})		
	},
	//卡账单
	cardBill(data){
		return request({url: '/manager/card/bill',method: 'post',data})		
	},
	//通道管理 支付 代理银行卡监控    总计
	countAll(data){
		return request({url: '/manager/carder/countAll',method: 'post',data})		
	},
	
	//订单管理 通道列表  hasRepay  1所有通道 2 补单可用通道
	paywayAll(data){
		return request({url: '/manager/pay_type/all',method: 'post',data,allowRepetition:true})		
	},
	//订单管理 订单状态
	stateList(data){
		return request({url: '/manager/order/stateList',method: 'post',data})		
	},
	//订单管理 补单统计
	replenishmentCountAll(data){
		return request({url: '/manager/order/replenishmentCountAll',method: 'post',data,allowRepetition:true})		
	},
	//订单管理 交易订单列表
	orderAllList(data){
		return request({url: '/manager/order/allList',method: 'post',data})		
	},
	//订单管理 交易订单 通知商家
	noticeIn(data){
		return request({url: '/manager/order/noticeIn',method: 'post',data})		
	},
	//代理进账报表
	reportAgent(data){
		return request({url: '/manager/report/agent',method: 'post',data})		
	},
	//商家进账报表
	reportTrader(data){
		return request({url: '/manager/report/trader',method: 'post',data})		
	},
	//商家出账报表
	reportTraderOut(data){
		return request({url: '/manager/report/traderOut',method: 'post',data})		
	},
	//商家费率报表
	reportTraderFee(data){
		return request({url: '/manager/report/traderFee',method: 'post',data})		
	},
	//通道报表
	reportPayType(data){
		return request({url: '/manager/report/payType',method: 'post',data})		
	},
	//通道管理 支付类型管理
	payTypeAll(data){
		return request({url: '/manager/pay_type/all',method: 'post',data,allowRepetition:true})		
	},
	//通道管理 支付类型管理
	repayTypeAll(data){
		return request({url: '/manager/repay_type/all',method: 'post',data,allowRepetition:true})		
	},
	//通道管理 代收付类型管理
	payTypeSetState(data){
		return request({url: '/manager/pay_type/setState',method: 'post',data})		
	},
	//通道管理 代付付类型管理
	repayTypeSetState(data){
		return request({url: '/manager/repay_type/setState',method: 'post',data})		
	},
	//通道管理 支付类型管理
	payTypeSetFee(data){
		return request({url: '/manager/pay_type/setFee',method: 'post',data})		
	},
	//通道管理 商家绑卡 商家列表
	traderCard(data){
		return request({url: '/manager/trader_card/trader',method: 'post',data})		
	},
	//通道管理 商家绑卡 商家已绑卡列表
	traderCardCard(data){
		return request({url: '/manager/trader_card/card',method: 'post',data})		
	},
	//通道管理 商家绑卡  码商列表
	carderList(data){
		return request({url: '/manager/trader_card/carderList',method: 'post',data})		
	},
	//通道管理 商家绑卡  绑卡
	bindCard(data){
		return request({url: '/manager/trader_card/bindCard',method: 'post',data})		
	},
	//通道管理 商家绑卡  商家已绑定卡 总计
	cardCount(data){
		return request({url: '/manager/trader_card/cardCount',method: 'post',data})		
	},
	//通道管理 商家绑卡  商家已绑定卡 总计
	orderCountAll(data){
		return request({url: '/manager/order/countAll',method: 'post',data})		
	},
	//通道管理 商家综合管理   银行卡列表
	bankList(data){
		return request({url: '/manager/card/bank',method: 'post',data})		
	},
	//通道管理 商家综合管理   数据列表
	traderCardList(data){
		return request({url: '/manager/trader_card/cardList',method: 'post',data})		
	},
	//通道管理 商家综合管理   统计数据
	traderCardcountAll(data){
		return request({url: '/manager/trader_card/countAll',method: 'post',data})		
	},
	//通道管理 商家综合管理   设置限额
	setTraderConfig(data){
		return request({url: '/manager/trader_card/setTraderConfig',method: 'post',data})		
	},
	//通道管理 卡处理   银行卡列表
	cardDealBankList(data){
		return request({url: '/manager/card_deal/allList',method: 'post',data})		
	},
	//通道管理 卡处理   银行卡列表
	cardDealCountAll(data){
		return request({url: '/manager/card_deal/countAll',method: 'post',data})
	},
	//通道管理 卡处理   银行卡列表
	cardDealstate(data){
		return request({url: '/manager/card_deal/state',method: 'post',data})		
	},
	//通道管理 卡处理   银行卡列表
	cardDealsSetState(data){
		return request({url: '/manager/card_deal/setState',method: 'post',data})		
	},
	//通道管理 银行卡开通设置  银行卡列表
	bankAllList(data){
		return request({url: '/manager/bank/allList',method: 'post',data})		
	},
	//通道管理 银行卡开通设置  设置
	banksetValue(data){
		return request({url: '/manager/bank/setValue',method: 'post',data})		
	},
	//订单管理 代付订单   订单列表
	orderOutList(data){
		return request({url: '/manager/order/outList',method: 'post',data})		
	},
	//订单管理 交易订单   代付
	replenishmentState(data){
		return request({url: '/manager/order/replenishmentState',method: 'post',data})		
	},
	//订单管理 交易订单   代付
	replenishment(data){
		return request({url: '/manager/order/replenishment',method: 'post',data})		
	},
	//订单管理 代付订单   代付订单状态
	outStateList(data){
		return request({url: '/manager/order/outStateList',method: 'post',data})		
	},
	//银行卡弹框内容  
	/* $id = input('id');//码商ID | 银行卡ID
        $type = input('type');//1-码商 2-银行卡 */
	infoTotal(data){
		return request({url: '/manager/carder/infoTotal',method: 'post',data})		
	},
	//新增码商
	carderAdd(data){
		return request({url: '/manager/carder/add',method: 'post',data})		
	},
	//码商费率列表
	carderFeeList(data){
		return request({url: '/manager/carder/feeList',method: 'post',data})		
	},
	//码商费率列表
	setCarderFee(data){
		return request({url: '/manager/carder/setFee',method: 'post',data})		
	},
	//交易订单  撤单
	ordercancel(data){
		return request({url: '/manager/order/cancel',method: 'post',data})		
	},
	//代付订单  主订单冲正
	orderRush(data){
		return request({url: '/manager/order/rush',method: 'post',data})		
	},
	/*
	 manager/order/repayReplenishment 代付子订单（补订单确认）接口  参数  id note备注
	 state=7 显示此按钮
	 */
	// repayReplenishment(data){
	// 	return request({url: '/manager/order/repayReplenishment',method: 'post',data})		
	// },
	//代付订单  重新派卡
	disCard(data){
		return request({url: '/manager/order/disCard',method: 'post',data})		
	},
	//代付订单 关闭子订单
	closeOrder(data){
		return request({url: '/manager/order/close',method: 'post',data})		
	},
	//代付订单 关闭主订单
	closeMainOrder(data){
		return request({url: '/manager/order/closeMain',method: 'post',data})		
	},
	//代付订单 子订单确认
	ensureOrder(data){
		return request({url: '/manager/order/ensure',method: 'post',data})		
	},
	//代付订单  四方代付订单的补订单确认接口  
	// state 改成1   is_rush 改成0
	// 现在四方上  state=2时是确认订单  state=7时是补确认订单
	// 新加的备注那列   is_rush=1时显示rush_note，  =0时显示note
	ensureMain(data){
		return request({url: '/manager/order/ensureMain',method: 'post',data})		
	},
	//代付订单  子订单冲正
	rushCard(data){
		return request({url: '/manager/order/rushCard',method: 'post',data})		
	},
	//提现订单 审核
	checkMentionOrder(data){
		return request({url: '/manager/order/checkMentionOrder',method: 'post',data})		
	},
	//交易订单通知
	inNotice(data){
		return request({url: '/manager/order/inNotice',method: 'post',data})		
	},
	//交易订单通知 备注
	setInNotice(data){
		return request({url: '/manager/order/setInNotice',method: 'post',data})		
	},
	//代付订单通知
	outNotice(data){
		return request({url: '/manager/order/outNotice',method: 'post',data})		
	},
	//代付订单通知 备注
	setOutNotice(data){
		return request({url: '/manager/order/setOutNotice',method: 'post',data})		
	},
	//通道管理 支付 代理银行卡监控 设置银行卡信息
	/* 
	 $id = input('card_id');
	 $card_no = input('card_no');//卡号
	 $telephone = input('telephone');//手机号
	 $eve_used_money = input('eve_used_money');//每日收款上限金额
	 $eve_used_num = input('eve_used_num');//每日收款上限笔数
	 $keep_gold = input('keep_gold');//余额留存上限
	 */
	setInfo(data){
		return request({url: '/manager/card/setInfo',method: 'post',data})		
	},
	//补单
	addOrder(data){
		return request({url: '/manager/order/addOrder',method: 'post',data})		
	},
	/* 商家操作日志
	 /**
	 public function logList()
	 {
	 $page = input('page', 1);
	 $size = input('size', Config::get('paginate.list_rows'));
	 $id = input('id');
	 $type = input('type', 0); //1-冻结|解冻 2-限额 3-手续费*/
	memberlogList(data){
		return request({url: '/manager/member/logList',method: 'post',data})		
	},
	//订单管理 代付订单 通知商家
	noticeOut(data){
		return request({url: '/manager/order/noticeOut',method: 'post',data})		
	},
	//白名单
	//$id = input('id');
	// $login_ip = input('login_ip');
	// $repay_ip = input('repay_ip');
	setWhitelist(data){
		return request({url: '/manager/member/setWhitelist',method: 'post',data})		
	},
	
	//http://api.tomaphin.com/manager/card/manualDisCardList 获取手动派卡列表的接口
	// $business_no = input('business_no');
	// $carder_id = input('carder_id');
	// $min_gold = input('min_gold', 0);
	// $max_gold = input('max_gold', 10000000);
	// $card = input('card');
	// $card_name = input('card_name');
	// $page = input('page', 1);
	// $size = input('size', Config::get('paginate.list_rows'));
	manualDisCardList(data){
		return request({url: '/manager/card/manualDisCardList',method: 'post',data})		
	},
	//  子订单手动派卡
    // {
    //     $id = input('id');
    //     $data = input('data');// money  bank_card_id
	manualDisCard(data){
		return request({url: '/manager/order/manualDisCard',method: 'post',data})		
	},
	//  主订单手动派卡
    // {
    //     $id = input('id');
    //     $data = input('data');// money  bank_card_id
	manualDisCardMain(data){
		return request({url: '/manager/order/manualDisCardMain',method: 'post',data})		
	},
	//平台提润
	  // $name = input('name');//持卡人姓名
	  //   $card = input('card');//卡号
	  //   $bank = input('bank');//银行名称
	  //   $money = input('money');//金额
	  //   $code = input('code');//谷歌验证
	mentionMoney(data){
		return request({url: '/manager/system/mentionMoney',method: 'post',data})		
	},
	
	//平台用户详情 包含可提现金额 money字段
	
	mentionInfo(data){
		return request({url: '/manager/system/info',method: 'post',data})		
	},
	//平台提润列表   type == 4
	
	mentionoutList(data){
		return request({url: '/manager/order/outList',method: 'post',data})		
	},
	
	//锁卡 解锁 银行卡 
	  //$id = input('id');
	  //$type = input('type'); //1-锁卡 2-解锁
	  
	  // $id = input('id');//卡ID
	  //         $order_id = input('order_id');
	  //         $water_no = input('water_no');
	  //         $card_order_id = input('card_order_id');
	  //         $type = input('type'); //1-锁卡 2-解锁
	discardLock(data){
		return request({url: '/manager/card/discardLock',method: 'post',data})		
	},
	
	
	//卡处理 时限设置	  
	  // $id = input('id');
	  // $time = input('time');
	carDealSetTime(data){
		return request({url: '/manager/card_deal/setTime',method: 'post',data})		
	},

	//卡处理 时限设置	  
	  // $id = input('id');
	  // $time = input('time');
	carderEdit(data){
		return request({url: '/manager/carder/edit',method: 'post',data})		
	},
	//已选卡列表查询
	// $page = input('page', 1);
	//         $size = input('size', Config::get('paginate.list_rows'));
	//         $order_id = input('order_id');
	//         $water_no = input('water_no');
	//         $card_order_id = input('card_order_id');
	choosedDisCardList(data){
		return request({url: '/manager/card/choosedDisCardList',method: 'post',data})		
	},
	//通道列表
	paywayList(data){
		return request({url: '/manager/system/paywayList',method: 'post',data})		
	},
	/**
	     * 设置商户单笔手续费
	     */
	 /*   public function setTraderFeeNum()
	    {
	        $trader_id = input('trader_id');
	        $fee_num = input('fee_num'); */
	setTraderFeeNum(data){
		return request({url: '/manager/member/setTraderFeeNum',method: 'post',data})		
	},
/**
     * 获取单笔手续费
     * @return \think\response\Json|void
     * @throws \think\db\exception\DataNotFoundException
     * @throws \think\db\exception\ModelNotFoundException
     * @throws \think\exception\DbException
     */
    /* public function getTraderFeeNum()
    {
        $trader_id = input('trader_id'); */
	getTraderFeeNum(data){
		return request({url: '/manager/member/getTraderFeeNum',method: 'post',data})		
	},
	
	/**
	     * 总开关
	     * @return \think\response\Json|void
	     * @throws \think\db\exception\DataNotFoundException
	     * @throws \think\db\exception\ModelNotFoundException
	     * @throws \think\exception\DbException
	     */
	    // public function setState()
	    // {
	    //     $id = input('id');
	    //     $state = input('state');//0-停用 1-启用
	setStatePayway(data){
		return request({url: '/manager/payway_type/setState',method: 'post',data})		
	},
	
	/**
	     * 收款开关
	     * @return \think\response\Json|void
	     * @throws \think\db\exception\DataNotFoundException
	     * @throws \think\db\exception\ModelNotFoundException
	     * @throws \think\exception\DbException
	     */
	  /*  public function setPayState()
	    {
	        $id = input('id');
	        $state = input('state');//0-停用 1-启用 */
		setPayState(data){
			return request({url: '/manager/payway_type/setPayState',method: 'post',data})		
		},
	/**
		 * 付款开关
		 * @return \think\response\Json|void
		 * @throws \think\db\exception\DataNotFoundException
		 * @throws \think\db\exception\ModelNotFoundException
		 * @throws \think\exception\DbException
		 */
	   /* public function setRepayState()
		{
			$id = input('id');
			$state = input('state');//0-停用 1-启用 */
		setRepayState(data){
			return request({url: '/manager/payway_type/setRepayState',method: 'post',data})		
		},
		//用户管理
/**
	 * 获取所有账号.
	 * @return \think\response\Json|void
	 * @throws \think\exception\DbException
	 */
  /*  public function getUsers()
	{
		$size = input('size', Config::get('paginate.list_rows'));  // 每页多少条.
		$page = input('page', 0); // 第几页. */

getUsers(data){
	return request({url: '/manager/user/getUsers',method: 'post',data})		
},
/**
	 * 新增账号.
	 * @return \think\response\Json|void
	 * @throws \think\db\exception\DataNotFoundException
	 * @throws \think\db\exception\ModelNotFoundException
	 * @throws \think\exception\DbException
	 */
   /* public function addUser(){
		$username = trim(input('user')); // 用户名.
		$password = strtolower(trim(input('password'))); // 密码. 前端限定再次输入密码. */
		
addUser(data){
	return request({url: '/manager/user/addUser',method: 'post',data})		
},
/**
	 * 重设账号密码.
	 * @return \think\response\Json|void
	 * @throws \think\db\exception\DataNotFoundException
	 * @throws \think\db\exception\ModelNotFoundException
	 * @throws \think\exception\DbException
	 */
/* public function resetUserPassword()
{
	$username = trim(input('username'));  // 用户名称.
	$password = trim(input('password')); // 密码. */
	resetUserPassword(data){
		return request({url: '/manager/user/resetUserPassword',method: 'post',data})		
	},
/**
	 * 重设账号谷歌验证.
	 * @return \think\response\Json|void
	 * @throws \think\db\exception\DataNotFoundException
	 * @throws \think\db\exception\ModelNotFoundException
	 * @throws \think\exception\DbException
	 */
  /*  public function setUserGoogleKey()
	{
		$username = trim(input('username'));  // 用户名称. */
	setUserGoogleKey(data){
		return request({url: '/manager/user/setUserGoogleKey',method: 'post',data})		
	},
	/**
		 * 删除账号.
		 * @return \think\response\Json|void
		 */
   /* public function delUser()
	{
		$id = input('id', 0); // 账号id.
		$username = input('username', ''); // 账号名称. */
	delUser(data){
		return request({url: '/manager/user/delUser',method: 'post',data})		
	},
	/**
		 * 获取角色.
		 * @return \think\response\Json|void
		 * @throws \think\exception\DbException
		 */
   /* public function getRoles()
{
	$size = input('size', Config::get('paginate.list_rows'));*/
	getRoles(data){
		return request({url: '/manager/user/getRoles',method: 'post',data})		
	},
	
	/**
		 * 给用户分配角色.
		 * @return \think\response\Json|void
		 * @throws \think\db\exception\DataNotFoundException
		 * @throws \think\db\exception\ModelNotFoundException
		 * @throws \think\exception\DbException
		 */
	/* public function configUserRole()
	{
		$manager_id = input('manager_id'); // 用户账号id.
		$role_id = input('role_id'); // 角色id. */
		configUserRole(data){
			return request({url: '/manager/user/configUserRole',method: 'post',data})		
		},
		
		/**
			 * 获取权限.
			 * @return \think\response\Json|void
			 * @throws \think\exception\DbException
			 */
		// public function getPermissions()
		// {
		//     $size = input('size', Config::get('paginate.list_rows')); // size 单页多少个.
		getPermissions(data){
			return request({url: '/manager/user/getPermissions',method: 'post',data})		
		},
		/**
		 * 给角色分配权限.
		 * @return \think\response\Json|void
		 * @throws \think\db\exception\DataNotFoundException
		 * @throws \think\db\exception\ModelNotFoundException
		 * @throws \think\exception\DbException
		 */
		// public function configRolePermission()
		// {
		//     $role_id = input('role_id'); // 角色id.
		//     $permission_id = input('permission_id'); // 权限id.
		//     $dealType = input('deal_type', '');  // 0 删除  1  选中.
		configRolePermission(data){
			return request({url: '/manager/user/configRolePermission',method: 'post',data})		
		},
		/**
			 * 删除角色.
			 * @return \think\response\Json|void
			 */
	   /* public function delRole()
		{
			$id = input('id', 0); // 角色id.
			$name = input('name', ''); // 角色名称. */
	delRole(data){
		return request({url: '/manager/user/delRole',method: 'post',data})		
	},
	/**
		 * 新增角色.
		 * @return \think\response\Json|void
		 * @throws \think\db\exception\DataNotFoundException
		 * @throws \think\db\exception\ModelNotFoundException
		 * @throws \think\exception\DbException
		 */
	/* public function addRole()
	{
		$name = trim(input('name', '')); // 角色名称. */
	addRole(data){
		return request({url: '/manager/user/addRole',method: 'post',data})		
	},
	//四方的支付方式报表加个通道的筛选项
	paywayTypeAll(data){
		return request({url: '/manager/payway_type/all',method: 'post',data})		
	},
	//四方通道报表
	paywayType(data){
		return request({url: '/manager/report/paywayType',method: 'post',data})		
	},
	/**
	     * 商家余额留存上限
	     * @return \think\response\Json|void
	     * @throws \think\db\exception\DataNotFoundException
	     * @throws \think\db\exception\ModelNotFoundException
	     * @throws \think\exception\DbException
	     */
	/*    public function setTraderKeepGold()
	    {
	        $id = input('id');
	        $amount = input('amount'); */
			
	setTraderKeepGold(data){
		return request({url: '/manager/member/setTraderKeepGold',method: 'post',data})
	},
	//数据总览
	total(data){
		return request({url: '/manager/report/total',method: 'post',data})
	},
	//代理管理 设置费率 最小值查询列表
	paywayTypeFeeList(data){
		return request({url: '/manager/member/paywayTypeFeeList',method: 'post',data})
	},
	//通道管理 设置费率 最大值查询费率列表
	paywayTypeAgentFeeList(data){
		return request({url: '/manager/payway_type/agentFeeList',method: 'post',data})
	},	
	//代理 上级级手续费
	agentFeeInfo(data){
		return request({url: '/manager/member/agentFeeInfo',method: 'post',data})		
	},
	//代理 下级手续费
	agentChildrenFeeList(data){
		return request({url: '/manager/member/agentChildrenFeeList',method: 'post',data})		
	},
	/**
	     * 设置商户匹配模式
	    public function setTraderPayMatchType()
	    {
	        $id = input('id');
	        $type = input('type'); //是否使用姓名匹配模式 1-否 2-是
	*/
   setTraderPayMatchType(data){
		return request({url: '/manager/member/setTraderPayMatchType',method: 'post',data})		
   },
   // 返回USDT代付订单状态
   usdtStatus(data){
		return request({url: '/manager/order/usdtStatus',method: 'post',data})		
   },
   // 返回USDT 链接类型
   usdtAddressType(data){
		return request({url: '/manager/order/usdtAddressType',method: 'post',data})		
   },
   // 返回USDT 代付订单列表
   usdtOrderList(data){
		return request({url: '/manager/order/usdtOrderList',method: 'post',data})		
   },
   // 返回USDT 代付订单 审核
   checkUsdtOrder(data){
		return request({url: '/manager/order/checkUsdtOrder',method: 'post',data})		
   },
   // 返回USDT 代付订单 冲正
   rushUsdtOrder(data){
		return request({url: '/manager/order/rushUsdtOrder',method: 'post',data})		
   },
   // USDT商户可选通道列表
   /* 商户勾选的代付通道列表
       public function traderRepayPaywayTypeList()
       {
           $business_no = input('business_no');
   */
   traderRepayPaywayTypeList(data){
		return request({url: '/manager/member/traderRepayPaywayTypeList',method: 'post',data})		
   },
   // 平台利润划转
   //     public function moneyTransfer()
   //     {
   //         $business_no = input('business_no');//商户号
   //         $data = input('data');// json字符串 ,格式： [{"payway_type_id":"1","money":"100"},{"payway_type_id":"2","money":"200"}]
   //         $code = input('code');//谷歌验证
   moneyTransfer(data){
		return request({url: '/manager/system/moneyTransfer',method: 'post',data})		
   },
	/**
        * 获取代理单笔手续费
       public function getAgentFeeNum()
       {
           $agent_id = input('agent_id');
	*/
	getAgentFeeNum(data){
		return request({url: '/manager/member/getAgentFeeNum',method: 'post',data})		
   },
   /**
        * 代理下的商户在某通道的单笔手续费列表        
       public function agentTraderFeeNumList()
       {
           $agent_id = input('agent_id');
	*/
	agentTraderFeeNumList(data){
		return request({url: '/manager/member/agentTraderFeeNumList',method: 'post',data})		
   },
	/**
        * 设置代理单笔手续费   
       public function setAgentFeeNum()
       {
           $agent_id = input('agent_id');
           $fee_num = input('fee_num');
	*/
	/**
        * 获取通道单笔手续费
       public function getFeeNum()
       {
           $id = input('id');
	*/
	getFeeNum(data){
		return request({url: '/manager/payway_type/getFeeNum',method: 'post',data})		
   },
	/**
        * 设置通道单笔手续费
       public function setFeeNum()
       {
           $id = input('id');
           $fee_num = input('fee_num');
	*/

	setFeeNum(data){
		return request({url: '/manager/payway_type/setFeeNum',method: 'post',data})		
   },
	//通道管理 币种管理
	CurrencyList(data){
		return request({url: '/manager/currency_type/getlist',method: 'post',data})		
	},
	//通道管理 添加币种
	addCurrencyType(data){
		return request({url: '/manager/Currency_Type/addCurrency',method: 'post',data,allowRepetition:true})		
	},
	//通道管理 修改币种汇率
	updateCurrencyRate(data){
		return request({url: '/manager/Currency_Type/setCurrencyExchangeRate',method: 'post',data,allowRepetition:true})		
	},
	//商户管理 展示商户绑定币种列表
	businessCurrencys(data){
		return request({url: '/manager/Currency_Type/businessCurrencys',method: 'post',data,allowRepetition:true})		
	},
	//商户管理 商户币种绑定
	bindBusinessCurrency(data){
		return request({url: '/manager/Currency_Type/bindBusinessCurrency',method: 'post',data,allowRepetition:true})		
	},
	//订单管理 线下入金订单列表
	offlineOrderList(data){
		return request({url: '/manager/order_offline/allList',method: 'post',data,allowRepetition:true})		
	},
	//订单管理 线下入金订单确认
	offlineOrderEnsure(data){
		return request({url: '/manager/order_offline/ensure',method: 'post',data,allowRepetition:true})		
	},
	//订单管理 线下入金订单关闭
	offlineOrderClose(data){
		return request({url: '/manager/order_offline/close',method: 'post',data,allowRepetition:true})		
	},
	//账号管理 代理账号获取费率列表
	agentOfflineFeeList(data){
		return request({url: '/manager/agent_currency/feeList',method: 'post',data,allowRepetition:true})		
	},
	//账号管理 代理账号获取费率列表
	setAgentOfflineFee(data){
		return request({url: '/manager/agent_currency/setFee',method: 'post',data,allowRepetition:true})		
	},
	//账号管理 代付手续费
	traderRepayFeeList(data){
		return request({url: '/manager/member/traderRepayFeeList',method: 'post',data,allowRepetition:true})		
	},
	//账号管理 设置代付手续费
	setTraderRepayFee(data){
		return request({url: '/manager/member/setTraderRepayFee',method: 'post',data,allowRepetition:true})		
	},
	// header金额
	getAcountTotalMoneyInfo(data){
		return request({url: '/manager/report/getAcountTotalMoneyInfo',method: 'post',data,allowRepetition:true})		
	},
	//通道充值手续费
	getFeeList(data){
		return request({url: '/manager/payway_type/feeList',method: 'post',data,allowRepetition:true})		
	},
	//通道充值手续费保存
	setFeeSaveAdd(data){
		return request({url: '/manager/payway_type/setFee',method: 'post',data,allowRepetition:true})		
	},
	//通道代付手续费列表
	getRepayFeeList(data){
		return request({url: '/manager/payway_type/repayFeeList',method: 'post',data,allowRepetition:true})		
	},
	//通道代付手续费保存
	getSetRepayFee(data){
		return request({url: '/manager/payway_type/setRepayFee',method: 'post',data,allowRepetition:true})		
	},
	//通道设置限额保存
	getSetGoldSave(data){
		return request({url: '/manager/payway_type/setGold',method: 'post',data,allowRepetition:true})		
	},
	agentRepayFeeList(data){
        return request({url: '/manager/member/agentRepayFeeList',method: 'post',data,allowRepetition:true})     
    },
	setAgentRepayFee(data){
        return request({url: '/manager/member/setAgentRepayFee',method: 'post',data,allowRepetition:true})      
    },
	manualPushPayway(data){
		return request({url: '/manager/order/manualPushPayway',method: 'post',data,allowRepetition:true})      
	},
	traderMoney(data){
		return request({url: '/manager/member/traderMoney',method: 'post',data,allowRepetition:true})      
	},
	currencyTypeFeeList(data){
		return request({url: '/manager/currency_type/feeList',method: 'post',data,allowRepetition:true})      
	},
	currencyTypeSetFee(data){
		return request({url: '/manager/currency_type/setFee',method: 'post',data,allowRepetition:true})      
	},
	traderCurrencysList(data){
		return request({url: '/manager/currency_type/traderCurrencys',method: 'post',data,allowRepetition:true})      
	},
	systemUserMoney(data){
		return request({url: '/manager/system/userMoney',method: 'post',data,allowRepetition:true})      
	},
    systemMentionMoney(data){
        return request({url: '/manager/system/mentionMoney',method: 'post',data,allowRepetition:true})      
    },
    relationTypeList(data){
        return request({url: '/manager/payway_type/relationTypeList',method: 'post',data,allowRepetition:true})      
    },
    setRelationType(data){
        return request({url: '/manager/payway_type/setRelationType',method: 'post',data,allowRepetition:true})      
    },
  // 代付通道查询.
    traderRepayList(data){
        return request({url: '/manager/member/traderRepayList',method: 'post',data,allowRepetition:true})      
    },
  // 代付通道设置.
    setTraderRepayWay(data){
        return request({url: '/manager/member/setTraderRepayWay',method: 'post',data,allowRepetition:true})      
    },
  // 代付通道分流列表显示.
    repayRatioList(data){
        return request({url: '/manager/member/repayRatioList',method: 'post',data,allowRepetition:true})      
    },
  // 代付通道分流更新保存.
    setRepayRatio(data){
        return request({url: '/manager/member/setRepayRatio',method: 'post',data,allowRepetition:true})      
    },

  // 销毁商户冻结余额.
    clearTraderUncertainMoney(data){
        return request({url: '/manager/member/clearTraderUncertainMoney',method: 'post',data,allowRepetition:true})      
    },
   // manager/order/outTypeList 
	// 代付订单类型查询
   setUncertainAmount(data){
		return request({url: 'manager/member/setUncertainAmount',method: 'post',data})	
   },
   // manager/member/getTraderWhite
	// 获取商户白名单
   getTraderWhite(data){
		return request({url: 'manager/member/getTraderWhite',method: 'post',data})	
   },
   // manager/member/setTraderWhite 
	// 设置商户白名单
   setTraderWhite(data){
		return request({url: 'manager/member/setTraderWhite',method: 'post',data})	
   },
   // manager/member/typeList 
	// 代理&商户分类列表
   typeList(data){
		return request({url: 'manager/member/typeList',method: 'post',data})	
   },
   // manager/member/allLogList 
	// 代付订单类型查询
	allLogList(data){
		return request({url: '/manager/member/allLogList',method: 'post',data})		
	},
   // /manager/member/moneyChangeList 
	// 商户余额账变列表
	moneyChangeList(data){
		return request({url: '/manager/member/moneyChangeList',method: 'post',data})		
	},
   // /manager/member/agentMoneyChangeList 
	// 商户余额账变列表
	agentMoneyChangeList(data){
		return request({url: '/manager/member/agentMoneyChangeList',method: 'post',data})		
	},
   // manager/order/checkNotifyLog
	// 商户余额账变列表
	checkNotifyLog(data){
		return request({url: '/manager/order/checkNotifyLog',method: 'post',data})		
	},
	//  手动生成代付订单	 /manager/order/addOrderPay
	addOrderPay(data){
		return request({url: '/manager/order/addOrderPay',method: 'post',data})		
	},
	//  /manager/member/getTypeDetail
	getTypeDetail(data){
		return request({url: '/manager/member/getTypeDetail',method: 'post',data})		
	},
   // /manager/order/ChangeOrderGold 
	// 修改代付订单金额
	ChangeOrderGold(data){
		return request({url: '/manager/order/ChangeOrderGold',method: 'post',data})		
	},
	// 查询代理余额
	agentMoney(data){
		return request({url: '/manager/member/agentMoney',method: 'post',data,allowRepetition:true})      
	},
	//代理 冻结/解冻余额
	setAgentAmount(data){
		return request({url: '/manager/member/setAgentAmount',method: 'post',data})		
	},
	//代理 冻结/解冻余额
	setPaywayCreditMoney(data){
		return request({url: '/manager/payway_type/setAmount',method: 'post',data})		
	},
	// /manager/payway_type/balanceChangelist  通道系统管理页面-》点击余额，展示余额变动明细
	// 请求参数：
	// id  通道ID
	// page
	// size
	balanceChangelist(data){
		return request({url: '/manager/payway_type/balanceChangelist',method: 'post',data})		
	},
	getCer(data){
		return request({url: '/manager/order/getCer',method: 'post',data})		
	},
	// 黑名单列表
	getBlackList(data){
		return request({url: '/manager/black_list_ctr/all',method: 'post',data})		
	},
	// 黑名单列表
	addBlackName(data){
		return request({url: '/manager/black_list_ctr/addName',method: 'post',data})		
	},
	// 黑名单列表
	delBlackName(data){
		return request({url: '/manager/black_list_ctr/deleteName',method: 'post',data})		
	},
	// telegram群组列表
	chatList(data){
		return request({url: '/manager/telegram_trader/allList',method: 'post',data})		
	},
	// 编辑群聊
	editChat(data){
		return request({url: '/manager/telegram_trader/edit',method: 'post',data})		
	},
	// 群组的商户列表
	chatTraderList(data){
		return request({url: '/manager/telegram_trader/traderList',method: 'post',data})		
	},
	// 群组的通道列表
	chatPaywayList(data){
		return request({url: '/manager/telegram_trader/paywayList',method: 'post',data})		
	},
	// 群组的删除
	deleteChat(data){
		return request({url: '/manager/telegram_trader/delete',method: 'post',data})		
	},
	// 消息通知列表
	cahtNoticeList(data){
		return request({url: '/manager/telegram_notice/allList',method: 'post',data})		
	},
	// 通知的商户列表
	getNoticeTraders(data){
		return request({url: '/manager/telegram_notice/getTraders',method: 'post',data})		
	},
	// 通知的通道列表
	getNoticePayways(data){
		return request({url: '/manager/telegram_notice/getPayways',method: 'post',data})		
	},
	// 发送消息
	telegramSendMessage(data){
		return request({url: '/manager/telegram_notice/sendMessage',method: 'post',data})		
	},
	// 重发消息
	resend(data){
		return request({url: '/manager/telegram_notice/resend',method: 'post',data})		
	},
	// 调整预付
	setRepaid(data){
		return request({url: '/manager/member/setRepaid',method: 'post',data})		
	},
	// 结算数据
	settleDetail(data){
		return request({url: '/manager/member/settleDetail',method: 'post',data})		
	},
	// 结算商户
	traderSettle(data){
		return request({url: '/manager/member/traderSettle',method: 'post',data})		
	},
	// 获取开关状态
	getSwitch(data){
		return request({url: '/manager/system/getSwitchSys',method: 'post',data})		
	},
	// 开关系统
	switchSys(data){
		return request({url: '/manager/system/switchSys',method: 'post',data})		
	},
	// 通道结算明细
	paywaySettleDetail(data){
		return request({url: '/manager/payway_type/settleDetail',method: 'post',data})		
	},
	// 通道结算
	paywayTypeSettle(data){
		return request({url: '/manager/payway_type/paywayTypeSettle',method: 'post',data})		
	},
	// 对接信息查询
	traderGatewayInfo(data){
		return request({url: '/manager/member/traderGatewayInfo',method: 'post',data})		
	},
	// 对接信息查询
	setTraderKey(data){
		return request({url: '/manager/member/setTraderKey',method: 'post',data})		
	},
	// /manager/pay_type/add  - 新增代收方式
	addPayType(data){
		return request({url: '/manager/pay_type/add',method: 'post',data})		
	},
	// /manager/pay_type/edit  - 编辑代收方式，编码不能修改
	editPayType(data){
		return request({url: '/manager/pay_type/edit',method: 'post',data})		
	},
	// /manager/pay_type/delete  - 删除代收方式
	delPayType(data){
		return request({url: '/manager/pay_type/delete',method: 'post',data})		
	},
	// /manager/repay_type/add  - 新增代付方式
	addRepayType(data){
		return request({url: '/manager/repay_type/add',method: 'post',data})		
	},
	// /manager/repay_type/edit  - 编辑代付方式，编码不能修改
	editRepayType(data){
		return request({url: '/manager/repay_type/edit',method: 'post',data})		
	},
	// /manager/repay_type/delete  - 删除代付方式
	delRepayType(data){
		return request({url: '/manager/repay_type/delete',method: 'post',data})		
	},
	// /manager/payway_type/addRelationType  - 通道绑定代收方式
	addRelationType(data){
		return request({url: '/manager/payway_type/addRelationType',method: 'post',data})		
	},
	// /manager/payway_type/deleteRelationType  - 通道删除代收方式
	deleteRelationType(data){
		return request({url: '/manager/payway_type/deleteRelationType',method: 'post',data})		
	},
	// /manager/member/moneyRepaidChangeList  - 商户预付变更明细
	moneyRepaidChangeList(data){
		return request({url: '/manager/member/moneyRepaidChangeList',method: 'post',data})		
	},
	// /manager/payway_type/moneyRepaidChangeList  - 通道预付变更明细
	paywayMoneyRepaidChangeList(data){
		return request({url: '/manager/payway_type/moneyRepaidChangeList',method: 'post',data})		
	},
	// /manager/payway_type/canBindRelationType  - 可绑定的代收方式
	canBindRelationType(data){
		return request({url: '/manager/payway_type/canBindRelationType',method: 'post',data})		
	},
	// /manager/payway_type/bindRelationType  - 已绑定的代收方式
	bindRelationType(data){
		return request({url: '/manager/payway_type/bindRelationType',method: 'post',data})		
	},
	// /manager/payway_type/editRelationType  - 绑定|解绑通道代收方式
	editRelationType(data){
		return request({url: '/manager/payway_type/editRelationType',method: 'post',data})		
	},
	// /manager/payway_type/edit  - 编辑通道
	editPayway(data){
		return request({url: '/manager/payway_type/edit',method: 'post',data})		
	},
	// /manager/member/deleteTrader  - 删除商户
	deleteTrader(data){
		return request({url: '/manager/member/deleteTrader',method: 'post',data})		
	},
	// /manager/payway_type/delete - 删除通道
	deletePayway(data){
		return request({url: '/manager/payway_type/delete',method: 'post',data})		
	},
	// /manager/payway_type/settleList -通道结算记录
	paywaySettleList(data){
		return request({url: '/manager/payway_type/settleList',method: 'post',data})		
	},
	// /manager/member/settleList -商户结算记录
	traderSettleList(data){
		return request({url: '/manager/member/settleList',method: 'post',data})		
	},
	// /manager/telegram_trader/getBot 查询机器人配置
	getTelegram(data){
		return request({url: '/manager/telegram_trader/getBot',method: 'post',data})
	},
	// /manager/telegram_trader/getBot 查询机器人配置
	setTelegram(data){
		return request({url: '/manager/telegram_trader/setBot',method: 'post',data})
	},
	
	// 代付通道绑定
    relationRepayTypeList(data){
        return request({url: '/manager/payway_type/relationRepayTypeList',method: 'post',data,allowRepetition:true})      
    },
    setRelationRepayType(data){
        return request({url: '/manager/payway_type/setRelationRepayType',method: 'post',data,allowRepetition:true})      
    },// /manager/payway_type/canBindRelationRepayType  - 可绑定的代付方式
	canBindRelationRepayType(data){
		return request({url: '/manager/payway_type/canBindRelationRepayType',method: 'post',data})		
	},
	// /manager/payway_type/bindRelationRepayType  - 已绑定的代付方式
	bindRelationRepayType(data){
		return request({url: '/manager/payway_type/bindRelationRepayType',method: 'post',data})		
	},
	// /manager/payway_type/editRelationRepayType  - 绑定|解绑通道代付方式
	editRelationRepayType(data){
		return request({url: '/manager/payway_type/editRelationRepayType',method: 'post',data})		
	},
	// /manager/payway_type/queryBanalce  - 通过通道id查询通道实时余额.
	queryBanalce(data){
		return request({url: '/manager/payway_type/queryBanalce',method: 'post',data,allowRepetition: true})		
	},
	// /manager/system/operateLog   系统操作日志
	operateLog(data){
		return request({url: '/manager/system/operateLog',method: 'post',data,allowRepetition: true})		
	},
	// /manager/system/operateType   系统操作日志类型，用于筛选框
	operateType(data){
		return request({url: '/manager/system/operateType',method: 'post',data,allowRepetition: true})		
	},
	// /manager/order/orderInTotal  代收订单页统计
	orderInTotal(data){
		return request({url: '/manager/order/orderInTotal',method: 'post',data,allowRepetition: true})		
	},
	// /manager/order/orderOutTotal  代付订单页统计
	orderOutTotal(data){
		return request({url: '/manager/order/orderOutTotal',method: 'post',data,allowRepetition: true})		
	},
	// 重置商户失败次数
	setTraderFailCount(data){
		return request({url: '/manager/member/setTraderFailCount',method: 'post',data})		
	},
	// 查询通道一键配置的商户列表
	traderPayType(data){
		return request({url: '/manager/payway_type/traderPayType',method: 'post',data})		
	},
	// 配置通道商户
	///manager/payway_type/bindTraderPayType 
	bindTraderPayType(data){
		return request({url: '/manager/payway_type/bindTraderPayType',method: 'post',data})		
	},
	// 解除通道绑定
	///manager/order/unbindPayway 
	unbindPayway(data){
		return request({url: '/manager/order/unbindPayway',method: 'post',data})		
	},
	// /manager/report/orderReportGroup  代收报表
	orderReportGroup(data){
		return request({url: '/manager/report/orderReportGroup',method: 'post',data})		
	},
	// /manager/report/orderPayReportGroup  代付报表
	orderPayReportGroup(data){
		return request({url: '/manager/report/orderPayReportGroup',method: 'post',data})		
	},
	// /manager/report/orderInTotal  代收报表统计
	reportOrderInTotal(data){
		return request({url: '/manager/report/orderInTotal',method: 'post',data})		
	},
	// /manager/report/orderOutTotal  代付报表统计
	reportOrderOutTotal(data){
		return request({url: '/manager/report/orderOutTotal',method: 'post',data})		
	},
	// /manager/member/getMemberFee  获取一键配置的商户费率
	getMemberFee(data){
		return request({url: '/manager/member/getMemberFee',method: 'post',data})		
	},
	// /manager/member/setMemberFee  一键配置的商户费率
	setMemberFee(data){
		return request({url: '/manager/member/setMemberFee',method: 'post',data})		
	},
	// /manager/member/memberRepaidReport  预付报表
	memberRepaidReport(data){
		return request({url: '/manager/member/memberRepaidReport',method: 'post',data})		
	},
	// /manager/order/queryCer    查询代付订单的凭证
	queryCer(data){
		return request({url: '/manager/order/queryCer',method: 'post',data})		
	},
	// /manager/order/getOrderPayOperatorLog    查询代付订单的操作日志
	getOrderPayOperatorLog(data){
		return request({url: '/manager/order/getOrderPayOperatorLog',method: 'post',data})		
	},
	// 解除异常账号
	setUserFailCount(data){
		return request({url: '/manager/user/resetFailCount',method: 'post',data})		
	},
	
	
	
}