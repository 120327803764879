<template>
	<div id="app">
		<div class="topNav" :class="setBackgroundColor($PayNameImg)" v-if="!this.$route.meta.noNav">
			<div class="navbar flexX flexcenter" style="border-bottom: 1px solid #c6c6c6;">
				
				<!-- <div class="logo" @click="showDataAll"><i class="el-icon-data-line"></i> {{$t('main.header.allData')}}  </div> -->
				<div class="logo">
					<span v-if="$PayNameImg == 'mark'"><i class="el-icon-coin cdanger"></i> MarkPay</span>
					<span v-else-if="$PayNameImg == 'rb'"><i class="el-icon-coin cdanger"></i> RbPay</span>
					<span v-else-if="$PayNameImg == 'bitong'"><i class="el-icon-coin cdanger"></i> 币通</span>
					<span v-else-if="$PayNameImg == 'qixing'"><i class="el-icon-coin cdanger"></i> 柒星</span>
					<span v-else-if="$PayNameImg == 'aipay'"><i class="el-icon-coin cdanger"></i> AiPay</span>
					<span v-else-if="$PayNameImg == 'yifa'"><i class="el-icon-coin cdanger"></i> 亿发</span>
					<span v-else><i class="el-icon-coin cdanger"></i> Ttpay</span>
					
				</div>
				
				<!-- <div style="padding: 0 10px;" @click="toggleClick">
					<div class="hamburger" :class="{'is-active':showSide==='hide'}">
						<div class="icon"></div>
					</div>
				</div> -->
			</div>
			<div class="topNavHeader">
				<!-- <div>
					<div v-for="(item,index) in dataTotalList" :key="index">
						<div>{{$t('main.header.' + item.name)}}:</div>	
						<span v-for="(value, key, index) in item.value" v-if="key == 'CNY'" :key="index">{{value}}<br /></span>
						
					</div>
				</div>
				<div>
				</div> -->
				<div>
					<!-- <el-dropdown class="mr20 username pointer" @command="language">
						<span class="el-dropdown-link">{{$t('main.language')}}</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item command='zh'>中文</el-dropdown-item>
							<el-dropdown-item command='en'>English</el-dropdown-item>
						</el-dropdown-menu>
						
					</el-dropdown> -->
					<span class="mr20 username pointer" v-if="showTirun" @click="gotoPageTirun">{{$t('main.header.platformLubrication')}}</span>
					<!-- <span class="mr20 username pointer" v-if="showTirun" @click="gotoPageusdtTrun">平台利润划转</span> -->
					<span class="flexX mr20 username flexcenter"><el-avatar style="background-color: cadetblue;" class="mr10" size="small"> {{username}} </el-avatar>{{username}}</span>
					
					<span class="mr20">
						<el-switch
						  v-model="switchState"
						  active-value="1"
						  inactive-value="2"
						  active-color="#13ce66"
						  inactive-color="#ff4949"
						  inactive-text="关"
						  active-text="开"
						  @change="changeSwitch"
						  >
						</el-switch>
					</span>
					
					
					<el-button size="mini" type="warning" @click="logout">{{$t('main.header.logOut')}}</el-button>
					
				</div>
			</div>
			
		</div>
		<div class="bottomMain" v-if="!this.$route.meta.noNav">
			<div class="sideBox" :class="[{sideHiden:showSide==='hide',sideShow:showSide==='show'}, setBackgroundColor($PayNameImg)]">
				<div v-for="(item,index) in nav" v-if="nav.length>0" :key="index" class="itemBox firstItemBox">
					<div class="navItem firstNav show pageNav" :class="{active:nowPath.includes(item.path) && item.meta.islink}"
					 @click="gotopage(item,index,'showOne')">
						<span><span style="color: #00a8ff;" :class="item.meta.iconS"></span> {{$t('main.menu.' + item.meta.title)}}</span>
						<span v-if="!item.meta.islink" :class="{'el-icon-arrow-right':!(show.showOne===index),'el-icon-arrow-down':(show.showOne === index)}"></span>
					</div>
					<div v-if="item.children && item.children.length > 0" class="childNav">
						<div v-for="(item2,index2) in item.children" :key="index2" class="itemBox">
							<div class="navItem twotNav pageNav" :class="{active:nowPath.includes(item2.path) && item2.meta.islink,ractive:show.showTwo===index2 && !item2.meta.islink,show:show.showOne === index}"
							 @click="gotopage(item2,index2,'showTwo')">
								<span>{{$t('main.menu.' + item2.meta.title)}}</span>
								<span v-if="!item2.meta.islink" :class="{'el-icon-arrow-right':show.showTwo!=index2}"></span>
							</div>

						</div>
					</div>
				</div>
			</div>
			<div class="threeDirectoryBox" :class="{noSide:showSide==='hide',hasSide:showSide==='show'}">
				<div v-if="threeDirectory.length > 0">
					<div class="threeChildNav">
						<div v-if="threeDirectory.length>0" v-for="(item3,index3) in threeDirectory" :key="index3" class="itemBox" :class="{active:nowPath===item3.path }">
							<div class="navItem pageNav" @click="gotopage(item3,index3,'showThree')">{{item3.meta.title}}</div>
						</div>
					</div>
				</div>
				<!-- <el-breadcrumb class="breadCrumb" separator="/">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item v-for="item in ParentDirector" v-if="ParentDirector.length>0">{{item.title}}</el-breadcrumb-item>
				</el-breadcrumb> -->
			</div>

			<div class="main-container" :class="{noSide:showSide==='hide',hasSide:showSide==='show',hasThreeDic:threeDirectory.length > 0}">
				<router-view />
			</div>
		</div>
		<div class="loginMain" v-if="this.$route.meta.noNav">
			<!-- 登录 -->
			<router-view />
		</div>
	</div>
</template>
<!-- <script>
	window.onbeforeunload = function(){
	    window.localStorage.removeItem("fourtid");
	    window.localStorage.removeItem("fourusername");
	}
</script> -->
<script>
	import local from './util/local.js'
	export default {
		name: 'Home',
		components: {
			// HelloWorld
		},
		data() {
			return {
				nav: [],
				show: {
					showOne: '',
					showTwo: '',
					showThree: ''
				},
				showSide: '', //是否显示左边导航
				nowPath: '',
				threeDirectory: [],
				ParentDirector: [],
				username: '',
				tid: '',
				t:null,
				hasInterval: false,
				systemcount:{},
				showTirun:false,
				dataTotalList:[
					{name:'agentCanUseBalance',key:'agent_total_money'},
					// {name:'agentFrozenBalance',key:'agent_total_money_uncertain'},
					{name:'traderCanUseBalance',key:'trader_total_money'},
					// {name:'traderFrozenBalance',key:'trader_total_money_uncertain'},
				],
				timer:null,
				// 系统开关
				switchState:2,
				intervalId:null
			}
		},
		beforeDestroy() {
            //清除定时器
            clearInterval(this.timer);
			if (this.intervalId) {
			  clearInterval(this.intervalId);
			  this.intervalId = null;
			}
		},
		created() {
			this.username = local.get("fourusername")
			this.tid = local.get("fourtid")
			
			this.getNav()
			this.nowPath = this.$route.path
			this.getTreeDirectoru()
			this.getBreadCrumb()
			this.getSwitchState(2);
			
			// this.cardDealstate()
			// this.getAcountTotalMoneyInfo()
			// clearInterval(this.timer);
			// this.timer = setInterval(()=>{
			// 	this.getAcountTotalMoneyInfo()
			// },5000)
		},
		mounted() {
			let beginTime = 0;//开始时间
			let differTime = 0;//时间差
			window.onunload = function (){
			  differTime = new Date().getTime() - beginTime;
			  if(differTime <= 3){
					window.localStorage.removeItem("fourusername");
					window.localStorage.removeItem("fourtid");
			  };
			};
			window.onbeforeunload = function (){
			  beginTime = new Date().getTime();
			};
			
		},
		methods: {
			 changeSwitch(val){
				let data = {
					'state':val
				}
				this.$api.switchSys(data).then(res => {
					if (res.status === 1) {
						this.getSwitchState(1)
					} else {
						this.getSwitchState(1)
						this.$message({
							type: 'error',
							message: res.msg
						});
					}
				
				})
			},
			getSwitchState(type){
				if(!this.username || !this.tid){
					return;
				}
				let data = {
				}
				this.$api.getSwitch(data).then(res => {
					// console.log(res)
					if (res.status === 1) {
						this.switchState = res.data.state
						if(type == 1){
							if(this.switchState == 1){
								this.$message({
									type: 'success',
									message: res.data.sysMsg
								});
							}
							if(this.switchState == 2){
								this.$message({
									type: 'error',
									message: res.data.sysMsg
								});
							}
						}
						
						
					} else {
						this.$message({
							type: 'info',
							message: res.msg
						});
					}
				
				})
			},
			setBackgroundColor(name){
				if(name == 'wanding'){
					return "wanding"
				}else if(name == 'laike-YL'){
					return "laike-YL"
				}else{
					return "default"
				}
				
			},
			language(val){
				this.$i18n.locale = val
				localStorage.setItem("lang", val)
			},
			getAcountTotalMoneyInfo(){
				if(!this.username || !this.tid){
					return
				}
				this.$api.getAcountTotalMoneyInfo({}).then(res => {
					if (res.status == 1) {
						this.dataTotalList.forEach(v=>{
							this.$set(v,'value',res.data[v.key])
						})
					}
				})
			},
			getTreeDirectoru() {
				// console.log('dssssdddd', this.$route.path)
				for (let i = 0; i < this.nav.length; i++) {
					if (this.nav[i].children.length > 0) {
						for (let j = 0; j < this.nav[i].children.length; j++) {
							if (this.nowPath.includes(this.nav[i].children[j].path)) {
								if (this.nav[i].children[j].meta.leven === 1) {
									this.threeDirectory = this.nav[i].children[j].children
								}
							}
						}
					}
				}
				// console.log(this.threeDirectory,'=====')
			},
			getBreadCrumb() {
				for (let i = 0; i < this.nav.length; i++) {
					if (this.nowPath == this.nav[i].path && this.nav[i].meta.islink) {
						this.show.showOne = i
						this.ParentDirector = [{
							title: this.nav[i].meta.title,
							path: this.nav[i].path
						}]
						break
					} else {
						let navChild = this.nav[i].children;
						for (let j = 0; j < navChild.length; j++) {
							if (this.nowPath == navChild[j].path && navChild[j].children.length == 0) {
								this.show.showOne = i
								this.show.showTwo = j
								this.ParentDirector = [{
									title: this.nav[i].meta.title,
									path: this.nav[i].path
								}, {
									title: navChild[j].meta.title,
									path: navChild[j].path
								}]
								break
							} else {
								let threeChild = navChild[j].children;
								for (let k = 0; k < threeChild.length; k++) {
									if (this.nowPath == threeChild[k].path) {
										this.show.showOne = i
										this.show.showTwo = j
										this.ParentDirector = [{
											title: this.nav[i].meta.title,
											path: this.nav[i].path
										}, {
											title: navChild[j].meta.title,
											path: navChild[j].path
										}, {
											title: threeChild[k].meta.title,
											path: threeChild[k].path
										}]
										break
									}
								}
							}
						}
					}
				}
			},
			getNav() {
				this.showTirun = this.$local.get('tiRun');
				let manage = this.$local.get('manage');
				this.$router.options.routes.forEach(item=>{
					if (item.path =='/userSys'||item.path =='/characterSys') {
						item.directory = manage
					}
				})
				let routes = this.$router.options.routes;
				let navAll = routes.filter(item => {
					if (item.directory) {
						return item
					}
				})
				let navArr = []
				for (let i = 0; i < navAll.length; i++) {
					if (navAll[i].meta.parentId === 0) {
						navAll[i].children = []
						navArr.push(navAll[i])
						navAll.splice(i, 1)
						i = i - 1
					}
				}
				for (let i = 0; i < navAll.length; i++) {
					if (navAll[i].meta.parentId != 0 && navAll[i].meta.leven === 1) {
						for (let j = 0; j < navArr.length; j++) {
							if (navAll[i].meta.parentId === navArr[j].meta.id) {
								navAll[i].children = []
								navArr[j].children.push(navAll[i])
							}
						}
					}
				}
				// 三级目录
				for (let i = 0; i < navAll.length; i++) {
					if (navAll[i].meta.parentId != 0 && navAll[i].meta.leven === 2) {
						for (let j = 0; j < navArr.length; j++) {
							for (let k = 0; k < navArr[j].children.length; k++) {
								if (navAll[i].meta.parentId === navArr[j].children[k].meta.id) {
									navArr[j].children[k].children.push(navAll[i])
								}
							}
						}
					}
				}
				this.nav = navArr
				// console.log(navAll, navArr)
			},
			gotopage(item, index, showNum) {
				
				clearInterval(this.timer);
				// this.getAcountTotalMoneyInfo()
				// this.timer = setInterval(()=>{
				// 	this.getAcountTotalMoneyInfo()
				// },5000)
				
				if (this.showSide == 'show') {
					this.showSide = ""
				}
				this.showChildren(index, showNum, item.meta.islink)
				if (this.$route.path === item.path && item.meta.islink) {
					this.$router.push({
						path: '/replace'
					})
					return
				}
				if (item.meta.islink) {
					this.$router.push(item.path)
					// this.$router.push({path: item.path, replace: true})
				}
				if (item.meta.leven === 1) {
					this.threeDirectory = item.children
				}
				// console.log('=====+++++======',this.$router)
			},
			toggleClick() {
				if (this.showSide === 'hide') {
					this.showSide = 'show'
				} else {
					this.showSide = 'hide'
				}
			},
			showChildren(index, showNum, islink) {
				if (showNum === 'showOne') {
					this.show.showTwo = ''
				}
				if (this.show[showNum] === index) {
					if (!islink) {
						this.show[showNum] = ''
					}
				} else {
					this.show[showNum] = index
				}
			},
			logout() {
				// this.$local.remove()
				this.$confirm('确认退出?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let data = {
						username: this.$local.get('fourusername')
					}
					this.$api.logout(data).then(res => {
						// console.log(res)
						if (res.status === 1) {
							this.$local.remove('fourtid')
							this.$local.remove('fourusername')
							this.$router.push('/')
						} else {
							this.$message({
								type: 'info',
								message: res.msg
							});
						}

					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			getData() {
				// this.t = setInterval(() => {
				// 	console.log('定时')
				// }, 30000)
			},
			gotoPageTirun(){
				// this.$router.push({path:'/Tirun', replace: true})
				if (this.$route.path === '/Tirun') {
					this.$router.push({path:'/replace'})
					return
				}
				this.$router.push({path:'/Tirun'})
			},
			gotoPageusdtTrun(){
				// this.$router.push({path:'/Tirun', replace: true})
				if (this.$route.path === '/usdtTrun') {
					this.$router.push({path:'/replace'})
					return
				}
				this.$router.push({path:'/usdtTrun'})
			},
			showDataAll(){
				if (this.$route.path === 'index') {
					this.$router.push({
						path: '/replace'
					})
					return
				}
				this.threeDirectory = []
				// this.$router.push({path:'/index', replace: true})
				this.$router.push('/index')
			}
		},
		watch: {
			$route(to, from) {
				this.username = local.get("fourusername")
				this.tid = local.get("fourtid")
				this.nowPath = this.$route.path
				this.getBreadCrumb()
				this.getTreeDirectoru()
				if (this.nowPath == "/login" || this.nowPath =="/googleVerification") {
					this.hasInterval = false
					// clearInterval(this.t)
					if (this.intervalId) {
					  clearInterval(this.intervalId);
					  this.intervalId = null;
					}
				} else {
					this.hasInterval = true
				}
			},
			hasInterval(val, old) {
				if (this.hasInterval) {
					this.getData()
					
					this.intervalId = setInterval(() => {
					      this.getSwitchState(2);
					    }, 30000); // 每5秒调用一次
				}
			}
		}
	}
</script>


<style lang="scss">
	@import '@/style/variables.scss';

	* {
		padding: 0;
		margin: 0;
	}
	
	.default{
		background: #e9ffeb;  /* fallback for old browsers */
	}
	.wanding{
		background: #16222A;  /* fallback for old browsers */
		background: -webkit-linear-gradient(to bottom, #3A6073, #16222A);  /* Chrome 10-25, Safari 5.1-6 */
		background: linear-gradient(to bottom, #3A6073, #16222A); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

	}
	.laike-YL{
		background: #16222A;  /* fallback for old browsers */
		background: -webkit-linear-gradient(to top, #3A6073, #16222A);  /* Chrome 10-25, Safari 5.1-6 */
		background: linear-gradient(to top, #3A6073, #16222A); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

	}
	
	.usdtCss{
	   color: #909399;
	   font-size: xx-small;
   	}
	#app {
		// font-family: Avenir, Helvetica, Arial, sans-serif;
		font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		// text-align: center;
		color: #303133;
		font-size: 16px;
	}
	.el-table--mini {
	    font-size: 0.735vw;
	}

	#nav {
		padding: 30px;
	}

	.topNav {
		height: 35px;
		// background-color: $menuBg;
		position: fixed;
		left: 0;
		top: 0;
		z-index: 99;
		width: 100%;
		display: flex;
		align-items: center;
		// justify-content: space-between;
		padding-right: 20px;
		.topNavHeader{
			display: flex;
			justify-content: flex-end;
			flex: 1;
			>div:first-child{
				color: #fff;
				display: flex;
				align-items: center;
				.money{
					margin-top: 3px;
				}
				>div{
					display: flex;
					margin-right: 20px;
					align-items: center
				}
			}
		}
		.username {
			color: $navColor
		}
	}

	.hamburger {
		display: inline-block;
		vertical-align: middle;
		width: 20px;
		height: 20px;
	}

	.hamburger .icon {
		background-color: transparent;
		border-bottom: 20px solid $warning;
		border-right: 20px solid transparent;
		transform: rotate(45deg);
	}

	.hideSidebar .hamburger .icon {
		background-color: transparent;
		border-bottom: 20px solid $warning;
		border-right: 20px solid transparent;
		transform: rotate(-135deg);
	}

	.hamburger.is-active {
		transform: rotate(180deg);
	}

	.sideBox {
		color: $navColor;
		text-align: left;


		.itemBox {
			padding-left: 18px;
			cursor: pointer;



			&.firstItemBox {
				padding-left: 0;

			}

			.childNav {
				// background-color: $subMenuBg;
				// font-size: 18px;
			}

			.navItem {
				padding: 8px 10px;
				display: none;

				align-items: center;
				justify-content: space-between;

				&.twotNav {
					font-size: 0.68vw;
					color: $navSubColor;
					padding-left: 12px;
				}

				&.firstNav {
					border-bottom: 1px solid rgba(107, 108, 109, 0.19);

				}

				&.show {
					display: flex;
				}

				&.active {
					color: $primary;
				}

				&.ractive {
					color: #f5f5f5;
				}
			}
		}

	}

	.threeDirectoryBox {
		.threeChildNav {
			display: flex;
			height: 40px;
			background-color: $threeNavbackColor;
			align-items: center;
			 box-sizing: border-box;
			 margin: 10px 10px 0;
			 border: 1px solid #ddd;
			.itemBox {
				// margin-right: 20px;
				padding: 0 10px;
				height: 100%;
				display: flex;
				align-items: center;
				border-bottom: solid 2px rgba(0, 0, 0, 0);
				cursor: pointer;
				margin-bottom: -1px;
				&.active {
					color: $primary;
					background: #fff;
					border-left: solid 1px $tableBorderColor;
					border-right: solid 1px $tableBorderColor;
				}
				&:first-child.active{
					border-left:none
				}
			}
		}
	}
	
	.activeClass {
		color: $warning;
	}

	.loginMain {
		min-height: 100vh;
	}
	
	 .el-dropdown-link {
	    cursor: pointer;
	    color: #409EFF;
	    font-size: 18px;
	  }
	  .el-table {
	    .cell {
	      font-size: 0.75rem; /* 这里的16px可以根据需要修改为其他大小 */
	    }
	  }
	  
</style>
